import {
  Routes,
  BrowserRouter as Router,
  Route,
  Outlet,
} from "react-router-dom";
import Product from "./pages/Product/Product";
import About from "./pages/About/About";
import Home from "./pages/Home/Home";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import "./App.css";
import { Container } from "@mui/material";

function Layout() {
  return (
    <Container sx={{ background: "#636363", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", minHeight: '100vh' }}>
      <NavBar />
      <Outlet />
      <Footer />
    </Container>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/product" element={<Product />} />
          <Route path="/about" element={<About />} />
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
