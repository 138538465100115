import { AppBar, Toolbar, Button, Stack, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

// @ts-ignore
import Logo from "../assets/4h.png";

const NavBar = () => {
  const navigate = useNavigate();
  return (
    <AppBar
      sx={{
        background: "#636363",
        top: 0,
        left: 0,
        right: 0,
        flexDirection: "row",
        zIndex: 9999
      }}
      elevation={0}
    >
      <Toolbar
        sx={{
          paddingInline: { sm: "2rem", xs: "1rem" },
          gap: { sm: "0.5rem", xs: "0.25rem" },
          marginInline: "auto",
          width: "100%",
          maxWidth: "1280px",
          justifyContent: "space-between",
          paddingBlock: "0.5rem",
        }}
      >
        <Stack direction="row" spacing={{ lg: 5, md: 2, sm: 1 }}>
          <Stack direction="row" alignItems="center" gap="0.5rem">
            <Box
              sx={{ height: { sm: "3.5rem", xs: "2rem" } }}
              onClick={() => navigate("/")}
            >
              <img src={Logo} alt="" height="100%" />
            </Box>
          </Stack>
          <Stack direction="row" spacing={{ lg: 5, md: 2, sm: 1 }}>
            <Button
              color="inherit"
              onClick={() => navigate("/product")}
              sx={{ fontSize: { sm: "1rem", xs: "0.75rem" } }}
            >
              Product
            </Button>
            <Button
              color="inherit"
              onClick={() => navigate("/about")}
              sx={{ fontSize: { sm: "1rem", xs: "0.75rem" } }}
            >
              About
            </Button>
          </Stack>
        </Stack>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#0099dd",
            fontSize: { sm: "1rem", xs: "0.75rem" },
          }}
          onClick={() =>
            (window.location.href = "https://calendly.com/najeemadeleke")
          }
        >
          Schedule a call
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
