import { Box, Stack, Typography } from "@mui/material";
// @ts-ignore
import Najm from "../../assets/najm.jpeg";
// @ts-ignore
import Khaza from "../../assets/Khaza.jpg";

const About = () => {
  return (
    <Stack
      alignItems="flex-start"
      color="white"
      sx={{
        gap: "0.5rem",
        width: "100%",
        maxWidth: "1280px",
        justifyContent: "space-between",
        paddingBlock: "7em",
        paddingInline: "2rem",
        marginInline: "0.5rem",
      }}
    >
      <Typography variant="h6" textAlign="left">
        Welcome to NAATS Group Inc., where innovation meets expertise. Join us
        on our journey as we redefine the future of Data Engineering and
        Generative AI.
      </Typography>
      <Typography variant="h3" paddingBlock={"3rem"} textAlign="left">
        Meet Our Team
      </Typography>
      <Stack gap="2rem">
        <Stack
          direction="row"
          gap="2rem"
          sx={{ flexDirection: { sm: "row", xs: "column" } }}
        >
          <Box
            sx={{
              width: { md: "20rem", sm: "15rem", xs: "100%" },
              height: { md: "20rem", sm: "15rem", xs: "100%" },
              borderRadius: "10%",
            }}
          >
            <img
              src={Najm}
              alt="founder profile pic"
              style={{ width: "100%", height: "100%", borderRadius: "10%" }}
            ></img>
          </Box>
          <Stack alignItems="flex-start">
            <Typography variant="h4">Najeem Adeleke</Typography>
            <Typography variant="h5">Founder & CEO</Typography>
            <Typography maxWidth="40rem" textAlign="left">
              Dr. Adeleke brings a wealth of experience from his tenure at
              esteemed companies like Upstart and Vanguard, where he honed his
              skills in Data Engineering and leadership. With a Doctorate in
              Engineering from Penn State University, his journey has been
              marked by a relentless pursuit of excellence. Driven by a passion
              for pioneering solutions, Dr. Adeleke has spent over a decade at
              the intersection of engineering and data science. His extensive
              background in data platform development, data pipeline
              orchestration and data ecosystem management has positioned NAATS
              Group Inc. as a leader in the industry. At NAATS Group Inc. he has
              spearheaded the development of groundbreaking Generative-AI
              solutions, revolutionizing how businesses derive insights from
              data. His commitment to excellence and innovation is evident in
              every aspect of our company's operations. With a proven track
              record of success in leading cross-functional teams and driving
              high-impact projects. Dr. Adeleke's ability to foster
              collaboration and inspire excellence has been instrumental in
              guiding NAATS Group Inc. towards continued growth and success.
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          gap="2rem"
          sx={{ flexDirection: { sm: "row", xs: "column" } }}
        >
          <Box
            sx={{
              width: { md: "20rem", sm: "15rem", xs: "100%" },
              height: { md: "20rem", sm: "15rem", xs: "100%" },
              borderRadius: "10%",
            }}
          >
            <img
              src={Khaza}
              alt="founder profile pic"
              style={{ width: "100%", height: "100%", borderRadius: "10%" }}
            ></img>
          </Box>

          <Stack alignItems="flex-start">
            <Typography variant="h4">Khaza Chowdhury</Typography>
            <Typography variant="h5">Founding Engineer</Typography>
            <Typography maxWidth="40rem" textAlign="left">
              Ex-Founding Full Stack Engineer at Clear Lake Specialites and
              Uvita Digital. Expert in developing streamlined applications,
              optimizing processes, and enhancing efficiency through innovative
              solutions.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default About;
