import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Stack,
  Typography,
} from "@mui/material";
// @ts-ignore
import video from "../../assets/PIP.mov";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import HealthAndSafetyOutlinedIcon from "@mui/icons-material/HealthAndSafetyOutlined";

const Product = () => {
  return (
    <Stack
      alignItems="center"
      color="white"
      flexWrap="wrap"
      flex={1}
      sx={{
        gap: "0.5rem",
        width: "100%",
        maxWidth: "1280px",
        justifyContent: "space-between",
        paddingBlock: "7em",
        paddingInline: "2rem",
        marginInline: "0.5rem",
      }}
    >
      <Stack
        direction="column"
        maxWidth="720px"
        paddingBottom="4rem"
        alignItems="center"
        gap="1rem"
      >
        <Typography variant="h2" fontSize="4rem" textAlign="center">
          Product Insight Prodigy<sup>&reg;</sup>
        </Typography>
        <Typography textAlign="center" fontSize="1.25rem" maxWidth="600px">
          PIP<sup>&reg;</sup> is a generative-AI solution that utilizes
          industry- and client-specific datasets to help businesses unlock
          hidden potentials, improve products, reduce cost and increase market
          share—all at minimal cost.
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#0099dd",
            fontSize: { sm: "1rem", xs: "0.75rem" },
          }}
          onClick={() =>
            (window.location.href = "https://calendly.com/najeemadeleke")
          }
        >
          Book a demo!
        </Button>
      </Stack>
      <CardContent>
        <Stack
          direction={{ sm: "row", xs: "column" }}
          justifyContent="space-between"
        >
          <Card
            component={Stack}
            padding="0.5rem"
            gap="0.5rem"
            sx={{ backgroundColor: "#636363", boxShadow: 0, color: "white" }}
          >
            <CardMedia component={QuestionAnswerOutlinedIcon} />
            <CardHeader
              title="Internal Virtual Assistant"
              sx={{ padding: 0 }}
            />
            <CardContent sx={{ padding: 0 }}>
              PIP can be trained to respond to questions about information
              available in your internal documentation systems, amongst many
              other sources, making it much easier for your employees to find
              the information they need.
            </CardContent>
          </Card>
          <Card
            component={Stack}
            padding="0.5rem"
            gap="0.5rem"
            sx={{ backgroundColor: "#636363", boxShadow: 0, color: "white" }}
          >
            <CardMedia component={HealthAndSafetyOutlinedIcon} />
            <CardHeader title="Health Recommendations" sx={{ padding: 0 }} />
            <CardContent sx={{ padding: 0 }}>
              PIP makes health screening recommendations to vulnerable patients
              within your internal patient population. It can be trained to
              identify at-risk patients within your internal patient population.
            </CardContent>
          </Card>
          <Card
            component={Stack}
            padding="0.5rem"
            gap="0.5rem"
            sx={{ backgroundColor: "#636363", boxShadow: 0, color: "white" }}
          >
            <CardMedia component={SummarizeOutlinedIcon} />
            <CardHeader title="Document Summarization" sx={{ padding: 0 }} />
            <CardContent sx={{ padding: 0 }}>
              PIP helps healthcare providers review and summarize internal
              documents faster. This would help enhance provider efficiency
              which would thereby lead to better patient outcomes.
            </CardContent>
          </Card>
        </Stack>
        <CardMedia
          sx={{ borderRadius: "0.75rem" }}
          component="video"
          src={video}
          autoPlay
          loop
          muted
        />
      </CardContent>
      <Typography>
        Our approach encompasses the design, development, and maintenance of
        robust and interconnected data ecosystems, followed by the creation and
        delivery of platform solutions tailored to address a wide range of
        business needs. We understand that every organization has unique
        challenges and goals, which is why we take a personalized approach to
        every project. Whether you're looking to optimize operational
        efficiency, enhance customer experiences, or drive innovation, our team
        is dedicated to delivering tailored solutions that drive measurable
        results. From data strategy and architecture to platform development and
        implementation, NAATS Group Inc. is your trusted partner for turning
        data into actionable insights and unlocking new opportunities for
        growth.
      </Typography>
    </Stack>
  );
};

export default Product;
