import { Stack, Typography, Button, CardContent, Card, CardMedia, CardHeader } from "@mui/material";
// import ImageSlider from "../../components/ImageSlider";
// import {ParticleNetworkAnimation} from "../../components/SplashPage";
// import { Pna } from "../../components/PNA";
import { ParticleSP } from "../../components/ParticleSP";
import { useNavigate } from "react-router-dom";
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import HealthAndSafetyOutlinedIcon from "@mui/icons-material/HealthAndSafetyOutlined";

// const pna = new ParticleNetworkAnimation();

const Home = () => {
  const navigate = useNavigate()
  // const splashPage = <div className="particle-network-animation"></div>
  // pna.init(splashPage);
  return (
    <div>
      <ParticleSP />
      <Stack
        alignItems="flex-start"
        color="white"
        direction="column"
        sx={{
          width: "100%",
          gap: "0.5rem",
          maxWidth: "1280px",
          justifyContent: "center",
          paddingTop: "17em",
          paddingInline: "2rem",
          marginInline: "0.5rem",

        }}
      >
        <Stack
          alignItems="center"
          color="white"
          sx={{
            backgroundColor: { sm: "rgba(99,99,99,100)", xs: "rgba(99,99,99,0)" },
            // gap: "0.5rem",
            width: { sm: "70%", xs: "100%" },
            maxWidth: "1280px",
            justifyContent: "space-between",
            // paddingBlock: "3rem",
            paddingInline: "2rem",  
            marginInline: { sm: "3rem", xs: "0rem" },
            // marginBottom: "7rem",
            zIndex: 999,
          }}
        >
          <Typography
            variant="h6"
            textAlign="center"
            fontSize={{ sm: "3rem", xs: "3.5rem" }}
          >
          Driving Success Through Data
          </Typography>
        </Stack>
        <Stack
          alignItems="center"
          color="white"
          sx={{
            backgroundColor: { sm: "rgba(99,99,99,100)", xs: "rgba(99,99,99,0)" },
            // gap: "0.5rem",
            width: { sm: "70%", xs: "100%" },
            maxWidth: "1280px",
            justifyContent: "space-between",
            // paddingBlock: "3rem",
            paddingInline: "2rem",  
            marginInline: { sm: "3rem", xs: "0rem" },
            // marginBottom: "7rem",
            zIndex: 999,
          }}
        >
          <Typography
            variant="h6"
            fontWeight='bold'
            textAlign="center"
            color='#42c5ff'
            fontSize={{ sm: "1.25rem", xs: "1.35rem" }}
          >
            Data Engineering - Advance Analytics - Machine Learning - Generative AI
          </Typography>
        </Stack>
        <Stack
          alignItems="center"
          color="white"
          sx={{
            backgroundColor: { sm: "rgba(99,99,99,100)", xs: "rgba(99,99,99,0)" },
            gap: "0.5rem",
            width: { sm: "70%", xs: "100%" },
            maxWidth: "1280px",
            justifyContent: "space-between",
            // paddingBlock: "3rem",
            paddingInline: "2rem",
            marginInline: { sm: "3rem", xs: "0rem" },
            marginBottom: "7rem",
            zIndex: 999,
          }}
        >
          <Typography
            variant="h6"
            textAlign="center"
            fontSize={{ sm: "1.25rem", xs: "1.35rem" }}
          >
            Empowering businesses to unlock the full potential of their data and 
            thereby enabling them to achieve strategic objectives with precision 
            and efficiency.
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#0099dd",
              fontSize: { sm: "1rem", xs: "0.75rem" },
              width: "--webkit-fill-available"
            }}
            onClick={() => navigate("/product")}
          >
            Explore Products
          </Button>
        </Stack>
      </Stack>
      <CardContent
        sx={{ zIndex: 999, position: "relative" }}
      >
        <Stack
          direction={{ sm: "row", xs: "column" }}
          justifyContent="space-between"
        >
          <Card
            component={Stack}
            padding="0.5rem"
            gap="0.5rem"
            sx={{ backgroundColor: "#636363", boxShadow: 0, color: "white" }}
          >
            <CardMedia component={CreditScoreIcon} />
            <CardHeader title="Financial Industry" sx={{ padding: 0 }} />
            <CardContent sx={{ padding: 0 }}>
              We help financial institutions enhance their decision-making processes,
              optimize operations, and provide better services to their customers by
              implementing data-driven strategies. We improved 
              risk management, increase operational efficiency, enhanced customer 
              satisfaction, and ultimately drive better business outcomes.
              {/* 1. Risk Management
              Predictive Analytics: Use historical data to predict future risks and take proactive measures.
              Credit Scoring: Develop advanced credit scoring models using machine learning to assess the creditworthiness of customers more accurately.
              2. Customer Insights and Personalization
              Customer Segmentation: Segment customers based on their transaction history and behavior to offer personalized financial products and services.
              Churn Analysis: Identify patterns that indicate customer churn and implement strategies to retain high-value customers.
              3. Fraud Detection and Prevention
              Anomaly Detection: Employ machine learning algorithms to detect unusual transactions that may indicate fraud.
              Behavioral Analysis: Analyze customer behavior to identify potential fraudulent activities.
              4. Investment Strategies
              Algorithmic Trading: Use data to develop trading algorithms that can execute trades at optimal times.
              Portfolio Optimization: Utilize data analytics to balance risk and return in investment portfolios.
              5. Operational Efficiency
              Process Automation: Implement Robotic Process Automation (RPA) to automate routine tasks, reducing manual errors and operational costs.
              Performance Monitoring: Use data to monitor the performance of various financial operations and identify areas for improvement.
              6. Regulatory Compliance
              Data Auditing: Maintain accurate and up-to-date records to ensure compliance with regulatory requirements.
              Reporting: Automate compliance reporting to meet regulatory deadlines and reduce the risk of non-compliance.
              7. Product Development
              Market Analysis: Use data to understand market trends and customer needs, guiding the development of new financial products.
              Feedback Analysis: Analyze customer feedback to continuously improve product offerings.
              8. Marketing and Sales
              Targeted Marketing: Leverage data to run targeted marketing campaigns and improve conversion rates.
              Sales Analytics: Use data to analyze sales performance and optimize sales strategies.
              9. Customer Service
              Chatbots and AI: Implement AI-driven chatbots to provide 24/7 customer service and resolve queries quickly.
              Sentiment Analysis: Analyze customer interactions to gauge sentiment and improve service quality. */}
            </CardContent>
          </Card>
          <Card
            component={Stack}
            padding="0.5rem"
            gap="0.5rem"
            sx={{ backgroundColor: "#636363", boxShadow: 0, color: "white" }}
          >
            <CardMedia component={HealthAndSafetyOutlinedIcon} />
            <CardHeader
              title="Healthcare Industry"
              sx={{ padding: 0 }}
            />
            <CardContent sx={{ padding: 0 }}>
              We help healthcare entities drive significant improvements 
              in patient care, operational efficiency, cost management, 
              clinical research, and overall healthcare delivery through 
              their data. By leveraging analytics, healthcare organizations 
              can make informed decisions that lead to better health outcomes 
              and enhanced business performance.
              {/* 1. Enhanced Patient Care and Outcomes
              Predictive Analytics: By analyzing historical patient data, healthcare providers can predict disease outbreaks, patient readmissions, and potential health risks. This enables proactive care and timely interventions.
              Personalized Medicine: Using genetic information and patient history, treatments can be tailored to individual patients, improving effectiveness and outcomes.
              2. Operational Efficiency
              Resource Management: Data can help optimize the use of hospital resources such as beds, staff, and medical equipment, ensuring they are available when and where needed.
              Workflow Optimization: Analyzing data on hospital operations can identify bottlenecks and inefficiencies, leading to improved processes and reduced wait times.
              3. Cost Reduction
              Fraud Detection: Data analytics can identify unusual patterns in billing and claims, helping to detect and prevent fraudulent activities.
              Cost Management: By understanding the cost drivers in healthcare, organizations can better manage expenses, negotiate with suppliers, and streamline procurement processes.
              4. Clinical Research and Development
              Drug Development: Big data can accelerate the drug discovery process by identifying potential compounds, predicting their effectiveness, and streamlining clinical trials.
              Epidemiology: Data analysis can track the spread of diseases, helping in the development of vaccines and public health strategies.
              5. Patient Engagement and Experience
              Personal Health Records: Providing patients with access to their own health data empowers them to make informed decisions about their care.
              Telemedicine: Data-driven insights can enhance telemedicine services, providing more accurate diagnoses and personalized care remotely.
              6. Regulatory Compliance
              Data Reporting: Automated data collection and reporting ensure compliance with health regulations and standards, reducing the risk of penalties and improving transparency.
              7. Market Analysis and Competitive Advantage
              Market Needs Assessment: Analyzing market data helps in understanding the demand for different healthcare services, enabling better planning and service delivery.
              Benchmarking: Data helps in comparing performance against industry standards, identifying areas for improvement and staying competitive.
              8. Patient Safety
              Adverse Event Prediction: By monitoring patient data in real-time, healthcare providers can predict and prevent adverse events such as medication errors and complications.
              9. Population Health Management
              Chronic Disease Management: Data can help in managing chronic diseases by monitoring patient adherence to treatment plans and predicting disease exacerbations.
              Community Health Initiatives: Data analysis helps in identifying health trends in communities, enabling targeted health interventions and resource allocation. */}
            </CardContent>
          </Card>
          <Card
            component={Stack}
            padding="0.5rem"
            gap="0.5rem"
            sx={{ backgroundColor: "#636363", boxShadow: 0, color: "white" }}
          >
            <CardMedia component={LocalPharmacyIcon} />
            <CardHeader title="Pharmaceutical Industry" sx={{ padding: 0 }} />
            <CardContent sx={{ padding: 0 }}>
            We assist pharmaceutical entities in harnessing the power of 
            advanced data analytics, machine learning to drive innovation, 
            enhance efficiency, and ultimately improve patient outcomes. 
            This data-driven approach fosters scientific discovery 
            and ensures that commercial and operational strategies are 
            aligned with market needs and regulatory requirements.
            </CardContent>
          </Card>
        </Stack>
      </CardContent>
    </div>
  );
};

export default Home;

