import { AppBar, Toolbar, Stack, Typography } from "@mui/material";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useNavigate } from "react-router-dom";
// @ts-ignore
import Logo from "../assets/5h.png";

import { Heading, FooterLink } from "./FooterStyles";

const Footer = () => {
  const navigate = useNavigate()
  return (
    <AppBar
      position="relative"
      sx={{
        background: "#636363",
        flexDirection: "column",
        width: "100vw",
        marginTop: "auto"
      }}
      elevation={0}
    >

      <Toolbar
        sx={{
          paddingInline: { sm: "2rem", xs: "1rem" },
          gap: { sm: "0.5rem", xs: "0.25rem" },

          justifyContent: "space-between",
          paddingBlock: "0.5rem",
          flexDirection: "row",
          display: "flex",
        }}
      >
        <Stack direction="column" gap="8px">
          <Stack direction="row" gap="8px" alignItems="center"
            onClick={() => navigate("/")}
          >
            <img style={{ height: "2rem", width: "2rem" }} src={Logo} alt="" height="100%" width="100%" />
            Naats Group Inc.
          </Stack>
          <Typography>Driving Success Through Data</Typography>
          <Stack direction="row" gap="8px" alignItems="center">
            <FooterLink href="https://www.linkedin.com/company/naats-group-inc/" display={"flex"} alignItems="center" gap="8px">
              <LinkedInIcon />
            </FooterLink>
            <FooterLink href={`mailto:support@naatsgroup.com`} display={"flex"} alignItems="center" gap="8px">
              <MailOutlineIcon />
            </FooterLink>
            <FooterLink href={`https://naatsgroup.slack.com`} display={"flex"} alignItems="center" gap="8px">
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M
6 15a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2h2zm1 0a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2a2 2 0 0 1-2-2zm2-8a2 2 0 0 1-2-2
a2 2 0 0 1 2-2a2 2 0 0 1 2 2v2zm0 1a2 2 0 0 1 2 2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2a2 2 0 0 1 2-2zm8 2a2 2 0 0 1 2-2a2 2 0 0 1 2 2a2 2
 0 0 1-2 2h-2zm-1 0a2 2 0 0 1-2 2a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2a2 2 0 0 1 2 2zm-2 8a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-2zm
0-1a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5a2 2 0 0 1 2 2a2 2 0 0 1-2 2z"/></svg>
            </FooterLink>
          </Stack>
        </Stack>
        <Stack direction="column">
          <Heading></Heading>
          <FooterLink href="#"></FooterLink>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
